<template>
  <div class="gallery pb-3 min-h-screen">
    <div class="flex justify-between items-end mb-6 relative">
      <p class="text-3xl font-bold">File Gallery</p>
      <div class="add-btn">
        <Button buttonText="Add" :forIcon="{ color: '#3b82f6', height: '24', width: '24' }" size="big" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Plus')" />
        <div class="add-btn-options invisible bg-white rounded-lg shadow-xl ease-linear transition-all duration-100 p-5 right-0 top-15 transform absolute cursor-default z-10">
          <div class="flex gap-3 mb-3 cursor-pointer last:mb-0" v-for="(content, index) in addBtnOptions" :key="index" @click="content.action">
            <div class="flex items-center"><component width="20" height="20" :is="content.icon" /></div>
            <div>{{ content.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="border pt-4 pb-8 bg-white rounded-md px-6 flex justify-between mb-6">
      <div class="flex-1 flex justify-start items-end">
        <div class="flex gap-2 items-end">
          <div class="w-96">
            <label class="text-neutral-500 text-xs font-medium mb-1 pl-3">Search File</label>
            <div class="relative">
              <div class="absolute left-4 top-1/2 transform -translate-y-1/2">
                <Search color="neutral-400" />
              </div>
              <TextField type="text" withIcon borderEnabled :placeholder="placeholderName" :enterKeyAction="doSearch" v-model="searchTerm" />
            </div>
          </div>
          <div class="flex gap-5">
            <Button buttonText="Search" type="secondary" size="regular" additionalClass="px-6 py-3" @action="doSearch()" />
          </div>
        </div>
      </div>
    </div>

    <div class="shadow-small p-6 bg-white rounded-md">
      <div v-if="pageLoading" class="py-48">
        <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
      </div>
      <div v-else>
        <BreadCrumb :list="breadCrumbList" @clickBreadCrumb="clickBreadCrumb" />
        <div class="grid grid-cols-4 gap-6" v-if="gallery.length > 0">
          <GalleryCard
            v-for="file in gallery"
            :key="file.id"
            :file="file"
            @moveTo="moveTo"
            @handleClick="openFolder"
            @details="showDetails"
            @confirm="confirm"
            option
            @copy_success="copySuccessHandler()"
          />
        </div>
        <div class="text-center my-28" v-else>
          <img src="@/assets/images/empty-content.svg" alt="empty" class="mx-auto" />
          <div class="font-medium text-xl py-5">{{ emptyContentText }}</div>
        </div>
        <div class="text-neutral-500 font-medium mt-4 text-sm" v-if="gallery.length > 0">Showing {{ start }} - {{ end }} out of {{ totalRecords }}</div>
        <div class="mt-8 flex justify-center items-center mb-6" v-if="gallery.length > 0">
          <pagination :currentPage="currentPage" :totalCount="totalRecords" :pageSize="pageSize" @onPageChange="onPageChange" />
        </div>
      </div>
    </div>

    <Modal :modalVisible="isUploadModalVisible" @close="toggleUploadFile" width="640px" id="upload">
      <template slot="modal-content">
        <GalleryUpload :uploadType="uploadType" @refetch="refetch" :activeId="mediaParentIdSelected" @closeAndRefetch="closeAndRefetch" />
      </template>
    </Modal>
    <Modal :modalVisible="visibleCardDetail" @close="toggleCardDetail" width="540px" id="detail">
      <template slot="modal-content">
        <FileDetails v-if="selectedFileDetail" :file="selectedFileDetail" @close="closeCardDetail()" />
      </template>
    </Modal>
    <Modal :modalVisible="visibleDeleteConfirmation" @close="toggleDeleteConfirmation" width="640px" id="delete">
      <template slot="modal-content">
        <div class="my-4">
          <p class="text-2xl font-bold mb-4">{{ deleteConfirmContent.title }}</p>
          <p class="text-sm text-neutral-500">{{ deleteConfirmContent.description }}</p>
          <div class="flex items-center justify-center mt-8 gap-8 px-20">
            <Button buttonText="Delete" type="secondary" size="full" @action="deleteFile(fileDeleted.id)" />
            <Button buttonText="Cancel" type="primary" size="full" @action="toggleDeleteConfirmation()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="visibleAlert" @close="toggleAlert" width="640px" id="delete">
      <template slot="modal-content">
        <div class="my-4">
          <h1 class="text-2xl font-bold mb-4">{{ alertContent.title }}</h1>
          <p class="text-sm text-neutral-500">{{ alertContent.description }}</p>
          <div class="flex items-center justify-center mt-8 px-20">
            <Button buttonText="Ok" type="primary" additionalClass="px-28" @action="toggleAlert()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="visibleMoveFile" @close="hideMoveFileModal" width="500px" id="moveFile">
      <template slot="modal-content">
        <div class="my-4 text-left">
          <h1 class="text-2xl font-bold mb-4 truncate">Move {{ moveToFileSelected?.title }} to...</h1>
          <div class="border border-grey-field rounded-lg">
            <div class="border-b shadow-md border-grey-field p-3 bg-neutral-100 flex items-center gap-4">
              <ArrowForward direction="left" class="cursor-pointer" @handleClick="backMoveFolder()" v-if="mediaParentIdSelected !== 0" />
              <div>{{ mediaParentIdSelected === 0 ? 'File Gallery' : currentPath }}</div>
            </div>
            <div class="max-h-48 py-10" v-if="isLoadMore">
              <vue-simple-spinner size="medium" message="Load more data..."></vue-simple-spinner>
            </div>
            <div class="max-h-40 min-h-28 overflow-auto" @scroll="onScroll" v-else>
              <div
                v-for="(galleryItem, galleryItemIndex) in listDataInModal"
                :key="galleryItemIndex + 'galleryItem'"
                @click="openFolderInModal(galleryItem)"
                class="px-3 py-2 gap-4 font-medium text-sm cursor-pointer flex items-center justify-between"
                :class="[moveToFileSelected.id === galleryItem.id ? 'bg-grey-field cursor-not-allowed' : 'hover:bg-yellow hover:text-white']"
              >
                <div class="flex items-center gap-3">
                  <VideoPlay width="20" height="20" color="neutral-400" v-if="setIconsFile(galleryItem.mimeType) === 'VIDEO'" />
                  <File color="neutral-400" width="20" height="20" v-else-if="setIconsFile(galleryItem.mimeType) === 'FOLDER'" />
                  <Document width="20" height="20" color="neutral-400" v-else />
                  <div class="">{{ truncateTitle(galleryItem.title) }}</div>
                </div>
                <Chevron width="14" height="14" color="#58595B" @handleClick="openFolderInModal(galleryItem)" v-if="galleryItem.mimeType === 'FOLDER' && moveToFileSelected.id !== galleryItem.id" />
              </div>
              <div class="text-center py-2 text-medium text-sm" v-if="totalRecords === listDataInModal.length && totalRecords > pageSize">This is end of data</div>
            </div>
            <div v-if="!isLoadMore && listDataInModal.length === 0" class="py-6 text-center">
              <div class="flex justify-center">
                <img src="@/assets/images/empty-content.svg" alt="empty" class="w-24" />
              </div>
              <div class="text-sm">No Files or Folders</div>
            </div>
          </div>
          <div class="flex items-center justify-end gap-2 mt-6">
            <Button buttonText="Cancel" type="secondary" size="big" @action="hideMoveFileModal" />
            <Button buttonText="Move Here" type="primary" size="big" :disabled="isSameFolder" @action="moveHere()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal :modalVisible="visibleSelectFile" @close="toggleSelectFile" width="80%" id="gallery">
      <template slot="modal-content">
        <GalleryModal
          @selectVideo="setSelectedFile"
          :currentFile="fileDeleted"
          :modalTitle="selectFileText.title"
          :modalDescription="selectFileText.description"
          :activityType="deleteType"
          buttonText="Replace"
          @close="toggleSelectFile()"
        />
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Search'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
    GalleryUpload: () => import(/* webpackChunkName: "GalleryUpload" */ '@/views/gallery/GalleryUpload'),
    GalleryCard: () => import(/* webpackChunkName: "GalleryCard" */ '@/views/gallery/GalleryCard'),
    FileDetails: () => import(/* webpackChunkName: "FileDetails" */ '@/views/gallery/FileDetails'),
    BreadCrumb: () => import(/* webpackChunkName: "BreadCrumb" */ '@/components/Breadcrumb/Breadcrumb'),
    VideoPlay: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/VideoPlay'),
    Document: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Document'),
    File: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/File'),
    GalleryModal: () => import(/* webpackChunkName: "GalleryModal" */ '@/components/GalleryModal/GalleryModal')
  },
  data() {
    return {
      typeList: ['All', 'Video', 'Document'],
      sortList: [
        {
          label: 'Last Updated',
          value: ''
        },
        {
          label: 'A-Z',
          value: ''
        },
        {
          label: 'Z-A',
          value: ''
        }
      ],
      addBtnOptions: [
        {
          title: 'Upload File',
          icon: () => import('@/components/Icons/Document'),
          action: () => {
            this.toggleUploadFile('FILE', true)
          }
        },
        {
          title: 'New Folder',
          icon: () => import('@/components/Icons/File'),
          action: () => {
            this.toggleUploadFile('FOLDER', true)
          }
        }
      ],
      selectedType: '',
      selectedSort: '',
      totalRecords: 0,
      isUploadModalVisible: false,
      currentPage: 1,
      pageSize: 12,
      visibleCardDetail: false,
      visibleDeleteConfirmation: false,
      visibleNewFolder: false,
      visibleMoveFile: false,
      visibleAlert: false,
      visibleSelectFile: false,
      selectedFileDetail: null,
      fileDeleted: null,
      searchTerm: '',
      newFolderName: '',
      uploadType: 'FILE',
      deleteType: 'FOLDER',
      mediaParentIdSelected: 0,
      breadCrumbList: [],
      isEmptyGallery: false,
      listDataInModal: [],
      isLoadMore: false,
      moveToFileSelected: null,
      isOpenFolderInModal: false,
      pathLocation: [],
      currentFolderId: 0,
      isInFolder: false,
      currentPageInModal: 1,
      totalRecordsInModal: 0,

      alertContent: {
        title: '',
        description: ''
      },
      selectFileText: {
        title: 'Select Replacement File',
        description: 'The file you want to delete is being used in Activity Template and/or Class Schedule, please select a replacement file from File Gallery.'
      },
      oldMediaItemId: null,
      isReplaced: false
    }
  },
  created() {
    this.isEmptyGallery = true
    this.getGallery()
  },
  computed: {
    ...mapGetters('gallery', ['gallery', 'galeryInModal']),
    ...mapGetters('common', ['pageLoading', 'refreshPage']),
    start() {
      return (this.currentPage - 1) * this.pageSize + 1
    },
    end() {
      const lastOnPage = (this.currentPage - 1) * this.pageSize + this.pageSize
      return lastOnPage > this.totalRecords ? this.totalRecords : lastOnPage
    },
    clientId() {
      return localStorage.getItem('client')
    },
    folderNameLength() {
      return this.newFolderName.length
    },
    placeholderName() {
      let result = this.mediaParentIdSelected === 0 ? 'Search File Gallery...' : `Search ${this.currentPath}...`
      return result
    },
    currentPath() {
      if (this.isInFolder) return this.pathLocation[this.pathLocationLastIndex]?.title
      return this.breadCrumbList.length > 0 ? this.breadCrumbList[this.breadCrumbList.length - 1]?.title : this.pathLocation[this.pathLocationLastIndex]?.title
    },
    emptyContentText() {
      return this.isEmptyGallery ? 'Your gallery is empty!' : `No result`
    },
    deleteConfirmContent() {
      return {
        title: `Pemanently delete this ${this.deleteType === 'FOLDER' ? 'folder' : 'file'}?`,
        description: 'This action cannot be undone'
      }
    },
    pathLocationLastIndex() {
      // if (this.pathLocation.length === 0) return 0
      return this.pathLocation.length - 1
    },
    isSameFolder() {
      if (this.moveToFileSelected?.pathLocation) return false
      let isFileExist = this.listDataInModal.filter((list) => list.id === this.moveToFileSelected.id)
      if (isFileExist.length > 0) return true
      return false
    }
  },
  watch: {
    refreshPage() {
      this.clickBreadCrumb('HOME')
    }
  },
  methods: {
    ...mapActions('gallery', ['GET_GALLERY', 'DELETE_FILE', 'GET_GALLERY_V3', 'GET_GALLERY_DETAILS_V3', 'MOVE_FILE_V3', 'REPLACE_FILES']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    truncateTitle(title) {
      if (title.length <= 35) return title
      return title.substring(0, 35) + '...'
    },
    clickBreadCrumb(path, index) {
      if (path === 'HOME') {
        this.currentPage = 1
        this.getGallery('')
        this.searchTerm = ''
        this.breadCrumbList = []
        this.isInFolder = false
        return
      }
      this.currentPage = 1
      this.breadCrumbList = this.breadCrumbList.filter((p, pathIdx) => pathIdx <= index)
      this.mediaParentIdSelected = path.id
      this.getData()
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.totalRecordsInModal <= 12) return
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.totalRecordsInModal !== this.listDataInModal.length) {
          this.isLoadMore = true
          setTimeout(() => {
            this.currentPageInModal++
            this.getData(false)
          }, 1000)
        }
      }
    },
    setIconsFile(type) {
      if (type.includes('video')) return 'VIDEO'
      if (type === 'FOLDER') return 'FOLDER'
      return 'DOCUMENT'
    },
    openFolder(fileSelected) {
      this.mediaParentIdSelected = fileSelected.id
      this.currentFolderId = fileSelected.id
      if (this.searchTerm && fileSelected.routeLocations) {
        this.breadCrumbList.push(fileSelected)
        this.pathLocation.push(fileSelected)
      } else {
        this.breadCrumbList.push(fileSelected)
        this.pathLocation.push(fileSelected)
      }
      this.isInFolder = true
      this.searchTerm = ''
      this.currentPage = 1
      this.getGalleryDetails(this.mediaParentIdSelected)
    },
    openFolderInModal(fileSelected) {
      if (fileSelected.mimeType !== 'FOLDER') return
      if (fileSelected.id === this.moveToFileSelected.id) return
      this.isOpenFolderInModal = true
      this.mediaParentIdSelected = fileSelected.id
      this.pathLocation.push(fileSelected)
      this.currentPageInModal = 1
      this.getData(false)
    },
    removeLastFolder() {
      return new Promise((resolve) => {
        this.pathLocation.pop()
        resolve(true)
      })
    },
    backMoveFolder() {
      this.currentPageInModal = 1
      this.removeLastFolder().then(() => {
        this.mediaParentIdSelected = this.pathLocation[this.pathLocationLastIndex]?.id ? this.pathLocation[this.pathLocationLastIndex]?.id : 0
        this.getData(false)
      })
    },
    moveTo(fileSelected) {
      this.moveToFileSelected = fileSelected
      this.mediaParentIdSelected = 0
      this.currentPageInModal = 1
      this.showMoveFileModal()
      this.getData(false)
    },
    moveHere() {
      const payloads = {
        customerId: this.clientId,
        mediaParentId: this.mediaParentIdSelected
      }
      this.MOVE_FILE_V3({
        mediaItemId: this.moveToFileSelected.id,
        payloads
      }).then((res) => {
        let moveType = this.moveToFileSelected.mimeType === 'FOLDER' ? 'Folder' : 'File'
        if (res.data.code === 200) {
          showVueToast(`${moveType} has been moved successfully!`, 'success', 2000)
        } else {
          showVueToast(`Failed to move ${moveType}`, 'error', 2000)
        }
        this.isOpenFolderInModal = false
        this.breadCrumbList = []
        this.hideMoveFileModal()
        this.getData()
      })
    },
    showMoveFileModal() {
      this.visibleMoveFile = true
    },
    hideMoveFileModal() {
      this.currentPage = 1
      this.listDataInModal = []
      this.isOpenFolderInModal = false
      this.mediaParentIdSelected = 0
      this.visibleMoveFile = false
      this.pathLocation = []
      this.currentFolderId = 0
    },
    selectCurrentPage() {
      if (this.visibleMoveFile) return this.currentPageInModal
      return this.currentPage
    },
    getGalleryDetails(mediaParentId, searchTerm = '', isCommit = true) {
      if (isCommit) {
        this.showLoading()
      } else {
        this.isLoadMore = true
      }
      this.GET_GALLERY_DETAILS_V3({
        params: {
          customerId: this.clientId,
          page: this.selectCurrentPage() - 1,
          limit: this.pageSize,
          query: searchTerm,
          direction: 'DESC'
        },
        mediaParentId,
        isCommit
      }).then((response) => {
        if (isCommit) {
          this.totalRecords = response.data.pagination.total
          if (this.gallery.length > 0) this.isEmptyGallery = false
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.hideLoading()
        } else {
          this.totalRecordsInModal = response.data.pagination.total
          if (this.isOpenFolderInModal) {
            if (this.currentPageInModal === 1) {
              this.listDataInModal = this.galeryInModal
            } else {
              const joinData = this.listDataInModal.concat(this.galeryInModal)
              this.listDataInModal = joinData
            }
          } else {
            const joinData = this.listDataInModal.concat(this.galeryInModal)
            this.listDataInModal = joinData
          }
          this.isLoadMore = false
        }
      })
    },
    getData(isCommit = true) {
      this.mediaParentIdSelected === 0 ? this.getGallery(this.searchTerm, isCommit) : this.getGalleryDetails(this.mediaParentIdSelected, this.searchTerm || '', isCommit)
    },
    doSearch() {
      this.currentPage = 1
      this.getData()
    },
    copySuccessHandler() {
      showVueToast('Link is copied successfully!', 'success', 2000)
    },
    onPageChange(page) {
      this.currentPage = page
      this.getData()
    },
    closeCardDetail() {
      this.toggleCardDetail()
      this.getData()
    },
    getGallery(searchTerm = '', isCommit = true) {
      this.mediaParentIdSelected = 0
      if (isCommit) {
        this.showLoading()
      } else {
        this.isLoadMore = true
      }
      this.GET_GALLERY_V3({
        params: {
          customerId: this.clientId,
          page: this.selectCurrentPage() - 1,
          limit: this.pageSize,
          query: searchTerm,
          direction: 'DESC'
        },
        isCommit
      }).then((response) => {
        if (isCommit) {
          this.totalRecords = response.data.pagination.total
          if (this.gallery.length > 0) this.isEmptyGallery = false
          window.scrollTo({ top: 0, behavior: 'smooth' })
          this.hideLoading()
        } else {
          this.totalRecordsInModal = response.data.pagination.total
          if (this.isOpenFolderInModal) {
            this.listDataInModal = this.galeryInModal
          } else {
            const joinData = this.listDataInModal.concat(this.galeryInModal)
            this.listDataInModal = joinData
          }
          this.isLoadMore = false
        }
      })
    },
    refetch() {
      this.getData()
    },
    closeAndRefetch() {
      this.toggleUploadFile()
      this.getData()
    },
    toggleUploadFile(type, isOpen) {
      if (isOpen) {
        this.uploadType = type
      }
      this.isUploadModalVisible = !this.isUploadModalVisible
    },
    toggleCardDetail() {
      this.visibleCardDetail = !this.visibleCardDetail
    },
    toogleNewFolder() {
      this.visibleNewFolder = !this.visibleNewFolder
    },
    showDetails(file) {
      this.toggleCardDetail()
      this.selectedFileDetail = file
    },
    async confirm(file) {
      this.fileDeleted = file
      this.deleteType = await this.handleMediaType(file.mimeType)
      this.toggleDeleteConfirmation()
    },
    handleMediaType(mimeType) {
      return new Promise((resolve, reject) => {
        let type = ''
        if (mimeType === 'FOLDER') {
          type = 'FOLDER'
        } else if (mimeType.includes('video')) {
          type = 'VIDEO'
        } else if (mimeType.includes('image')) {
          type = 'IMAGE'
        } else {
          type = 'DOCUMENT'
        }
        resolve(type)
      })
    },
    toggleDeleteConfirmation() {
      this.visibleDeleteConfirmation = !this.visibleDeleteConfirmation
    },
    toggleAlert() {
      this.visibleAlert = !this.visibleAlert
    },
    toggleSelectFile() {
      this.visibleSelectFile = !this.visibleSelectFile
    },
    setSelectedFile(file) {
      this.showLoading()
      const params = {
        customerId: file.customerId,
        newMediaItemId: file.id
      }
      this.REPLACE_FILES({ params, oldMediaItemId: this.oldMediaItemId })
        .then((res) => {
          if (res?.data?.code == 200) {
            this.isReplaced = true
            this.visibleSelectFile = false
            this.deleteFile(this.fileDeleted.id)
            return
          }
          this.alertContent.title = `Failed to delete the ${this.selectedType.toLowerCase()}`
          this.alertContent.description = `An error occurred when delete this ${this.selectedType.toLowerCase()}, please contact admin`
          this.visibleSelectFile = false
          this.visibleDeleteConfirmation = false
          this.hideLoading()
        })
        .catch(() => {
          this.visibleAlert = true
          this.alertContent.title = `Failed to delete the ${this.selectedType.toLowerCase()}`
          this.alertContent.description = `An error occurred when delete this ${this.selectedType.toLowerCase()}, please contact admin`
          this.visibleSelectFile = false
          this.visibleDeleteConfirmation = false
          this.hideLoading()
        })
    },
    deleteFile(id) {
      this.oldMediaItemId = id
      this.showLoading()
      this.DELETE_FILE({
        id: id,
        payloads: {
          customerId: this.clientId
        }
      })
        .then((res) => {
          this.visibleSelectFile = false
          this.visibleDeleteConfirmation = false
          if (res?.data?.code == 200) {
            showVueToast(`${this.fileDeleted.title} has been ${this.isReplaced ? 'replaced' : 'deleted'} successfully!`, 'success', 3000)
            this.getData()
            this.isReplaced = false
            return
          }
          this.hideLoading()
          showVueToast(`Failed!`, 'error', 2000)
        })
        .catch((err) => {
          if (err.code === 422) {
            this.handleErrorAction()
            return
          }
          this.alertContent.title = `Failed to delete the ${this.selectedType.toLowerCase()}`
          this.alertContent.description = `An error occurred when delete this ${this.selectedType.toLowerCase()}, please contact admin`
          this.visibleSelectFile = false
          this.visibleAlert = false
          this.visibleDeleteConfirmation = false
          this.hideLoading()
        })
    },
    handleErrorAction() {
      this.hideLoading()
      this.toggleDeleteConfirmation()
      if (this.deleteType === 'FOLDER') {
        const content = {
          title: 'Folder Deletion Alert',
          description: 'The folder still contains file(s). Please ensure all files are moved to another location or deleted before attempting to delete it again.'
        }
        this.alertContent = content
        this.toggleAlert()
        return
      }
      this.toggleSelectFile()
    }
  }
}
</script>
<style lang="scss" scoped>
.add-btn {
  &:hover .add-btn-options {
    visibility: visible;
    opacity: 1;
    padding-top: 15px;
  }
}

.file-card {
  box-shadow: 0px 2px 4px rgba(39, 40, 51, 0.12);
}
.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.p-3 {
  padding: 12px !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
p {
  margin: 0;
}
a {
  color: #58595b;
}
button {
  margin: 0 !important;
  --tw-border-opacity: 1;
  border-color: rgba(253, 233, 210, var(--tw-border-opacity)) !important;
  &:hover {
    background: #fde9d2;
  }
}
</style>
