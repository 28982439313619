var render = function () {
  var _vm$moveToFileSelecte;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gallery pb-3 min-h-screen"
  }, [_c('div', {
    staticClass: "flex justify-between items-end mb-6 relative"
  }, [_c('p', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("File Gallery")]), _c('div', {
    staticClass: "add-btn"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add",
      "forIcon": {
        color: '#3b82f6',
        height: '24',
        width: '24'
      },
      "size": "big",
      "icon": function icon() {
        return import(
        /* webpackChunkName: 'icon' */
        '@/components/Icons/Plus');
      }
    }
  }), _c('div', {
    staticClass: "add-btn-options invisible bg-white rounded-lg shadow-xl ease-linear transition-all duration-100 p-5 right-0 top-15 transform absolute cursor-default z-10"
  }, _vm._l(_vm.addBtnOptions, function (content, index) {
    return _c('div', {
      key: index,
      staticClass: "flex gap-3 mb-3 cursor-pointer last:mb-0",
      on: {
        "click": content.action
      }
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c(content.icon, {
      tag: "component",
      attrs: {
        "width": "20",
        "height": "20"
      }
    })], 1), _c('div', [_vm._v(_vm._s(content.title))])]);
  }), 0)], 1)]), _c('div', {
    staticClass: "border pt-4 pb-8 bg-white rounded-md px-6 flex justify-between mb-6"
  }, [_c('div', {
    staticClass: "flex-1 flex justify-start items-end"
  }, [_c('div', {
    staticClass: "flex gap-2 items-end"
  }, [_c('div', {
    staticClass: "w-96"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3"
  }, [_vm._v("Search File")]), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "absolute left-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "neutral-400"
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": _vm.placeholderName,
      "enterKeyAction": _vm.doSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)]), _c('div', {
    staticClass: "flex gap-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Search",
      "type": "secondary",
      "size": "regular",
      "additionalClass": "px-6 py-3"
    },
    on: {
      "action": function action($event) {
        return _vm.doSearch();
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "shadow-small p-6 bg-white rounded-md"
  }, [_vm.pageLoading ? _c('div', {
    staticClass: "py-48"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('BreadCrumb', {
    attrs: {
      "list": _vm.breadCrumbList
    },
    on: {
      "clickBreadCrumb": _vm.clickBreadCrumb
    }
  }), _vm.gallery.length > 0 ? _c('div', {
    staticClass: "grid grid-cols-4 gap-6"
  }, _vm._l(_vm.gallery, function (file) {
    return _c('GalleryCard', {
      key: file.id,
      attrs: {
        "file": file,
        "option": ""
      },
      on: {
        "moveTo": _vm.moveTo,
        "handleClick": _vm.openFolder,
        "details": _vm.showDetails,
        "confirm": _vm.confirm,
        "copy_success": function copy_success($event) {
          return _vm.copySuccessHandler();
        }
      }
    });
  }), 1) : _c('div', {
    staticClass: "text-center my-28"
  }, [_c('img', {
    staticClass: "mx-auto",
    attrs: {
      "src": require("@/assets/images/empty-content.svg"),
      "alt": "empty"
    }
  }), _c('div', {
    staticClass: "font-medium text-xl py-5"
  }, [_vm._v(_vm._s(_vm.emptyContentText))])]), _vm.gallery.length > 0 ? _c('div', {
    staticClass: "text-neutral-500 font-medium mt-4 text-sm"
  }, [_vm._v("Showing " + _vm._s(_vm.start) + " - " + _vm._s(_vm.end) + " out of " + _vm._s(_vm.totalRecords))]) : _vm._e(), _vm.gallery.length > 0 ? _c('div', {
    staticClass: "mt-8 flex justify-center items-center mb-6"
  }, [_c('pagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "totalCount": _vm.totalRecords,
      "pageSize": _vm.pageSize
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1) : _vm._e()], 1)]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isUploadModalVisible,
      "width": "640px",
      "id": "upload"
    },
    on: {
      "close": _vm.toggleUploadFile
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('GalleryUpload', {
    attrs: {
      "uploadType": _vm.uploadType,
      "activeId": _vm.mediaParentIdSelected
    },
    on: {
      "refetch": _vm.refetch,
      "closeAndRefetch": _vm.closeAndRefetch
    }
  })], 1)], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleCardDetail,
      "width": "540px",
      "id": "detail"
    },
    on: {
      "close": _vm.toggleCardDetail
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_vm.selectedFileDetail ? _c('FileDetails', {
    attrs: {
      "file": _vm.selectedFileDetail
    },
    on: {
      "close": function close($event) {
        return _vm.closeCardDetail();
      }
    }
  }) : _vm._e()], 1)], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleDeleteConfirmation,
      "width": "640px",
      "id": "delete"
    },
    on: {
      "close": _vm.toggleDeleteConfirmation
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "my-4"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-4"
  }, [_vm._v(_vm._s(_vm.deleteConfirmContent.title))]), _c('p', {
    staticClass: "text-sm text-neutral-500"
  }, [_vm._v(_vm._s(_vm.deleteConfirmContent.description))]), _c('div', {
    staticClass: "flex items-center justify-center mt-8 gap-8 px-20"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "type": "secondary",
      "size": "full"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteFile(_vm.fileDeleted.id);
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "primary",
      "size": "full"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleDeleteConfirmation();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleAlert,
      "width": "640px",
      "id": "delete"
    },
    on: {
      "close": _vm.toggleAlert
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "my-4"
  }, [_c('h1', {
    staticClass: "text-2xl font-bold mb-4"
  }, [_vm._v(_vm._s(_vm.alertContent.title))]), _c('p', {
    staticClass: "text-sm text-neutral-500"
  }, [_vm._v(_vm._s(_vm.alertContent.description))]), _c('div', {
    staticClass: "flex items-center justify-center mt-8 px-20"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Ok",
      "type": "primary",
      "additionalClass": "px-28"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleAlert();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleMoveFile,
      "width": "500px",
      "id": "moveFile"
    },
    on: {
      "close": _vm.hideMoveFileModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "my-4 text-left"
  }, [_c('h1', {
    staticClass: "text-2xl font-bold mb-4 truncate"
  }, [_vm._v("Move " + _vm._s((_vm$moveToFileSelecte = _vm.moveToFileSelected) === null || _vm$moveToFileSelecte === void 0 ? void 0 : _vm$moveToFileSelecte.title) + " to...")]), _c('div', {
    staticClass: "border border-grey-field rounded-lg"
  }, [_c('div', {
    staticClass: "border-b shadow-md border-grey-field p-3 bg-neutral-100 flex items-center gap-4"
  }, [_vm.mediaParentIdSelected !== 0 ? _c('ArrowForward', {
    staticClass: "cursor-pointer",
    attrs: {
      "direction": "left"
    },
    on: {
      "handleClick": function handleClick($event) {
        return _vm.backMoveFolder();
      }
    }
  }) : _vm._e(), _c('div', [_vm._v(_vm._s(_vm.mediaParentIdSelected === 0 ? 'File Gallery' : _vm.currentPath))])], 1), _vm.isLoadMore ? _c('div', {
    staticClass: "max-h-48 py-10"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "medium",
      "message": "Load more data..."
    }
  })], 1) : _c('div', {
    staticClass: "max-h-40 min-h-28 overflow-auto",
    on: {
      "scroll": _vm.onScroll
    }
  }, [_vm._l(_vm.listDataInModal, function (galleryItem, galleryItemIndex) {
    return _c('div', {
      key: galleryItemIndex + 'galleryItem',
      staticClass: "px-3 py-2 gap-4 font-medium text-sm cursor-pointer flex items-center justify-between",
      class: [_vm.moveToFileSelected.id === galleryItem.id ? 'bg-grey-field cursor-not-allowed' : 'hover:bg-yellow hover:text-white'],
      on: {
        "click": function click($event) {
          return _vm.openFolderInModal(galleryItem);
        }
      }
    }, [_c('div', {
      staticClass: "flex items-center gap-3"
    }, [_vm.setIconsFile(galleryItem.mimeType) === 'VIDEO' ? _c('VideoPlay', {
      attrs: {
        "width": "20",
        "height": "20",
        "color": "neutral-400"
      }
    }) : _vm.setIconsFile(galleryItem.mimeType) === 'FOLDER' ? _c('File', {
      attrs: {
        "color": "neutral-400",
        "width": "20",
        "height": "20"
      }
    }) : _c('Document', {
      attrs: {
        "width": "20",
        "height": "20",
        "color": "neutral-400"
      }
    }), _c('div', {}, [_vm._v(_vm._s(_vm.truncateTitle(galleryItem.title)))])], 1), galleryItem.mimeType === 'FOLDER' && _vm.moveToFileSelected.id !== galleryItem.id ? _c('Chevron', {
      attrs: {
        "width": "14",
        "height": "14",
        "color": "#58595B"
      },
      on: {
        "handleClick": function handleClick($event) {
          return _vm.openFolderInModal(galleryItem);
        }
      }
    }) : _vm._e()], 1);
  }), _vm.totalRecords === _vm.listDataInModal.length && _vm.totalRecords > _vm.pageSize ? _c('div', {
    staticClass: "text-center py-2 text-medium text-sm"
  }, [_vm._v("This is end of data")]) : _vm._e()], 2), !_vm.isLoadMore && _vm.listDataInModal.length === 0 ? _c('div', {
    staticClass: "py-6 text-center"
  }, [_c('div', {
    staticClass: "flex justify-center"
  }, [_c('img', {
    staticClass: "w-24",
    attrs: {
      "src": require("@/assets/images/empty-content.svg"),
      "alt": "empty"
    }
  })]), _c('div', {
    staticClass: "text-sm"
  }, [_vm._v("No Files or Folders")])]) : _vm._e()]), _c('div', {
    staticClass: "flex items-center justify-end gap-2 mt-6"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "secondary",
      "size": "big"
    },
    on: {
      "action": _vm.hideMoveFileModal
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Move Here",
      "type": "primary",
      "size": "big",
      "disabled": _vm.isSameFolder
    },
    on: {
      "action": function action($event) {
        return _vm.moveHere();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleSelectFile,
      "width": "80%",
      "id": "gallery"
    },
    on: {
      "close": _vm.toggleSelectFile
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('GalleryModal', {
    attrs: {
      "currentFile": _vm.fileDeleted,
      "modalTitle": _vm.selectFileText.title,
      "modalDescription": _vm.selectFileText.description,
      "activityType": _vm.deleteType,
      "buttonText": "Replace"
    },
    on: {
      "selectVideo": _vm.setSelectedFile,
      "close": function close($event) {
        return _vm.toggleSelectFile();
      }
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }